'use client';

import React from 'react';

export default function Custom403() {
  return (
    <div className="flex flex-grow flex-col min-h-full justify-center">
      <div className="text-center">
        <h1 className="text-2xl">This page is for privileged users only</h1>
        <p className="text-xl">Please log in</p>
      </div>
    </div>
  );
}
